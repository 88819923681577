exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-clients-tsx": () => import("./../../../src/pages/clients.tsx" /* webpackChunkName: "component---src-pages-clients-tsx" */),
  "component---src-pages-index-cn-tsx": () => import("./../../../src/pages/index.cn.tsx" /* webpackChunkName: "component---src-pages-index-cn-tsx" */),
  "component---src-pages-index-es-tsx": () => import("./../../../src/pages/index.es.tsx" /* webpackChunkName: "component---src-pages-index-es-tsx" */),
  "component---src-pages-index-jp-tsx": () => import("./../../../src/pages/index.jp.tsx" /* webpackChunkName: "component---src-pages-index-jp-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lectures-tsx": () => import("./../../../src/pages/lectures.tsx" /* webpackChunkName: "component---src-pages-lectures-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-prize-tsx": () => import("./../../../src/pages/prize.tsx" /* webpackChunkName: "component---src-pages-prize-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-swag-tsx": () => import("./../../../src/pages/swag.tsx" /* webpackChunkName: "component---src-pages-swag-tsx" */),
  "component---src-pages-tour-tsx": () => import("./../../../src/pages/tour.tsx" /* webpackChunkName: "component---src-pages-tour-tsx" */)
}

