module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-fix-fouc@1.0.5_gatsby@5.13.4_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.2._fyyklhbgz7r4k72amtpy36pvjm/node_modules/gatsby-plugin-fix-fouc/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.4_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.2.0_react@18.2.0__react@18.2.0__vg3f645vqeail3ix5sgl6bn65i/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
